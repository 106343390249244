import React from "react"

import { Link } from 'gatsby'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Link to="/" >
      <div className=" text-gray-400 hover:text-bloghalka-main p-5 border-b text-center">
        <p className="text-bloghalka-main">ouch...</p>
        <h3 className="font-bold  mt-3 text-4xl">404</h3>
      </div>
    </Link>

  </Layout>

)

export default NotFoundPage
